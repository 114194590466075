import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="filter-turnos"
export default class extends Controller {
  static targets = ["fecha", "searchInput"]

  static values = {
    url: String
  }

  onClickSearch(e) {
    e.preventDefault();

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        q: this.searchInputTarget.value,
        fecha: this.fechaTarget.value
      }
    })
  }
}
