import { Controller } from "@hotwired/stimulus"
import '@popperjs/core';
import * as tempusDominus from '@eonasdan/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
import customDateFormat from '@eonasdan/tempus-dominus/dist/plugins/customDateFormat'
import { load, faFiveIcons } from '@eonasdan/tempus-dominus/dist/plugins/fa-five'
tempusDominus.extend(customDateFormat)
tempusDominus.extend(load);

const datepickerConfig = {
  display: {
    components: {
      decades: false,
      year: true,
      month: true,
      date: true,
      hours: false,
      minutes: false,
      seconds: false,
    }
  },
  localization: {
    locale: 'es',
    format: 'dd/MM/yyyy'
  }
}

// import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';

// Connects to data-controller="datepicker"
export default class extends Controller {
  static values = {
    readonly: Boolean
  }

  connect() {
    const td = new tempusDominus.TempusDominus(this.element, datepickerConfig)

    if (this.readonlyValue) td.disable();
  }
}
