import { Controller } from "@hotwired/stimulus"
import '@popperjs/core';
import * as tempusDominus from '@eonasdan/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
import customDateFormat from '@eonasdan/tempus-dominus/dist/plugins/customDateFormat'
import { load, faFiveIcons } from '@eonasdan/tempus-dominus/dist/plugins/fa-five'
tempusDominus.extend(customDateFormat)
tempusDominus.extend(load)

const timepickerConfig = {
  display: {
    viewMode: "clock",
    components: {
      decades: false,
      year: false,
      month: false,
      date: false,
      hours: true,
      minutes: true,
      seconds: true,
      useTwentyfourHour: true
    }
  },
  localization: {
    locale: 'es',
    format: 'HH:mm'
  }
}

// Connects to data-controller="timepicker"
export default class extends Controller {
  static values = {
    readonly: Boolean
  }

  connect() {
    const td = new tempusDominus.TempusDominus(this.element, timepickerConfig)
    if (this.readonlyValue) td.disable();
  }
}
