import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="buscar-reportes"
export default class extends Controller {
  static values = {
    url: String,
    registro: Number
  }

  imprimir(e) {
    e.preventDefault()

    get(`${this.urlValue}?registro=${this.registroValue}`, { responseKind: 'turbo-stream' })
  }
}
