import { Controller } from "@hotwired/stimulus";
import '@popperjs/core';
import * as tempusDominus from '@eonasdan/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
import customDateFormat from '@eonasdan/tempus-dominus/dist/plugins/customDateFormat';
import { load } from '@eonasdan/tempus-dominus/dist/plugins/fa-five';
import { get, post } from '@rails/request.js'
tempusDominus.extend(customDateFormat);
tempusDominus.extend(load);

const datepickerConfig = {
  display: {
    components: {
      decades: false,
      year: true,
      month: true,
      date: true,
      hours: false,
      minutes: false,
      seconds: false,
    }
  },
  localization: {
    locale: 'es',
    format: 'dd/MM/yyyy'
  },
  restrictions: {
    daysOfWeekDisabled: [],
    minDate: undefined
  },
  allowInputToggle: true
}

// Connects to data-controller="turnos-range-datetimepicker"
export default class extends Controller {
  static targets = ["fecha", "hora", "usuario", "matricula", "nombre", "dni", "registro", "estado"]

  static values = {
    readonly: Boolean,
    dias: Array,
    url: String
  }

  connect() {
    if (this.diasValue.length === 0) {
      datepickerConfig.restrictions.daysOfWeekDisabled = [];
    } else {
      const daysOfWeekEnabled = [0, 1, 2, 3, 4, 5, 6].filter(day => !this.diasValue.includes(day));
      datepickerConfig.restrictions.daysOfWeekDisabled = daysOfWeekEnabled;
    }

    const td = new tempusDominus.TempusDominus(this.element.querySelector("#turno-fecha"), datepickerConfig)
    if (this.readonlyValue) td.disable()
  }

  onClickDate() {
    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        fecha: this.fechaTarget.value
      }
    })
  }

  selectHour() {
    this.element.querySelector("#horaInicio").value = this.horaTarget.value
  }

  submit(e) {
    e.preventDefault()

    const accept = e.currentTarget

    accept.setAttribute('disabled', true)
    accept.classList.add('disabled')

    post('/artturnos', {
      responseKind: 'turbo-stream',
      body: {
        Fecha: this.fechaTarget.value,
        HoraInicio: this.horaTarget.value,
        usuario: this.usuarioTarget.value,
        Matricula: this.matriculaTarget.value,
        Nombre: this.nombreTarget.value,
        DNIPaciente: this.dniTarget.value,
        Registro: this.registroTarget.value,
        Estado: this.estadoTarget.value,
      }
    })
  }
}
